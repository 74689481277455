import React, {useContext, useEffect, useState} from 'react';
import './App.css';
import Header from "./header/Header";
import Main from "./pages/main/Main";
import Footer from "./footer/Footer";
import HowToBuy from "./pages/howToBuy/HowToBuy";
import {BrowserRouter, Route, Routes, Navigate} from 'react-router-dom'
import Contacts from "./pages/contacts/Contacts";
import Catalog from "./pages/catalog/Catalog";
import AdminPage from "./pages/admin/AdminPage";
import Basket from "./pages/basket/Basket";
import ErrorPage from "./pages/error/ErrorPage";
import {Context} from "./index";
import Menu from "./header/nav/menu/Menu";
import Auth from "./pages/auth/Auth";
import CardPage from "./pages/cardPage/CardPage";
import {observer} from "mobx-react-lite";
import {check} from "./http/userAPI";
import MyLoader from "./components/loader/MyLoader";
import PersonalAccount from "./pages/personalAccount/PersonalAccount";
import {Provider} from "react-redux";
import {store} from "./features/store";

const App = observer( () => {
    const {user} = useContext(Context)
    const [loading, setLoading] = useState(true)
    const [menuOpened, setMenuOpened] = useState(false);
    const onBurgerClick = () => {
        setMenuOpened(!menuOpened);
    };

    // useEffect(() => {
    //
    //         check().then(data => {
    //             // user.setUser(true)
    //             // user.setIsAuth(true)
    //         }).finally(() => setLoading(false))
    //
    //
    // }, [])
    // if(loading){
    //     return <MyLoader/>
    // }

    return (
        <Provider store={store}>
            <BrowserRouter>
                <div className="App">
                    <Header menuOpened={menuOpened} setMenuOpened={setMenuOpened} onBurgerClick={onBurgerClick}/>
                    <Routes>
                        {user.isAuth && <Route path='/admin' element={<AdminPage/>}/>}
                        {user.isAuth && <Route path='/basket' element={<Basket/>}/>}
                        <Route path='/' element={<Catalog/>}/>
                        <Route path='/login' element={<Auth/>}/>
                        <Route path='/registration' element={<Auth/>}/>
                        <Route path='/catalog'  element={<Catalog/>}/>
                        <Route path='/catalog/:id' element={<CardPage/>}/>
                        <Route path='/about' element={<Main/>}/>
                        <Route path='/kakkupit' element={<HowToBuy/>}/>
                        <Route path='/contacts' element={<Contacts/>}/>
                        <Route path='/profile' element={<PersonalAccount/>}/>
                        <Route path='*' element={<ErrorPage/>} />
                        <Route path='/admin' element={<AdminPage/>}/>
                        <Route path='/basket' element={<Basket />}/>

                    </Routes>

                    <Footer/>

                    <Menu menuOpened={menuOpened} setMenuOpened={setMenuOpened} onBurgerClick={onBurgerClick}/>

                </div>





            </BrowserRouter>
        </Provider>

    );
})

export default App;

import React from 'react';
import style from './ErrorPage.module.css'
import styleContainer from '../../common/styles/Container.module.css';
import error from '../../img/error/error.png'
import {NavLink} from "react-router-dom";


const ErrorPage = () => {
    return (
        <div className={style.error_container}>
            <div className={styleContainer.container}>
                <div className={style.error_block}>
                    <div className={style.information}>
                        <h3 className={style.error_title}>Уууупс!
                            Вы потерялись
                            в темноте :)</h3>
                        <div className={style.info_text}>Возвращайтесь к нам!</div>
                        <div>
                            <NavLink to={'/catalog'}>
                                <button className={style.mainButton}>Каталог</button>
                            </NavLink>

                        </div>

                        <div className={style.text_description}>
                            <span>
                            К сожалению, запрошенная страница не найдена на сервере или временно недоступна.

                    </span>
                        </div>
                    </div>
                    <div className={style.error_text}>
                        <div className={style.text}>4</div>
                        <div className={style.text_red}>0</div>
                        <div className={style.text}>4</div>
                        {/*<img src={error} alt=""/>*/}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default ErrorPage;
import React from "react";
import BaseModal from "../modals/base-modal/BaseModal";

import phoneImage from "../../img/askQuestion/phone.svg";
import emailImage from "../../img/askQuestion/mail.svg";
import viberIcon from "../../img/askQuestion/viber.svg";
import telegramIcon from "../../img/askQuestion/telegram.svg";
import whatsAppIcon from "../../img/askQuestion/whatsapp.svg";
import skype from "../../img/askQuestion/skype.svg";

import style from "./RequestCall.module.css";

const RequestCall = ({ isOpen, handleClose, submitRequest }) => (
  <BaseModal isOpen={isOpen} handleClose={handleClose} style={{height: '420px'}}>
    <div className={style.wrap}>
      <div className={style.title}>Заказать звонок</div>
      <div className={style.question}>Как связаться с вами?</div>
      <div className={style.nav}>
        <a href="tel:+375 25 549 42 70 ">
          <img src={phoneImage} alt="phone" />
        </a>
        <a href="mailto:info@smax.com">
          <img src={emailImage} alt="email" />
        </a>
        <a href="viber://chat?number=+375255494270">
          <img src={viberIcon} alt="Viber" />
        </a>
        <a href="https://t.me/Stasmakstreid">
          <img src={telegramIcon} alt="Telegram" />
        </a>

        <a href="https://wa.clck.bar/375255494270">
          <img src={whatsAppIcon} alt="WhatsApp" />
        </a>
        <a href="https://wa.clck.bar/375255494270">
          <img src={skype} alt="Skype" />
        </a>
      </div>
      <div className={style.phoneBlock}>
        <input
          type="text"
          placeholder="+375  ( __ ) ___ __ __"
          className={style.phone_number}
        />
      </div>
      <div className={style.info}>
        * Чтобы мы могли вам ответить, необходимо указать полный номер телефона либо адрес электронной почты
      </div>
      <div>
        <button className={style.send_code_button} onClick={submitRequest}>
          Отправить запрос
        </button>
      </div>
    </div>
  </BaseModal>
);

export default RequestCall;

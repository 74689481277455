import {$authHost, $host} from "./index";


export const createType = (type) => {
    const {data} = $authHost.post('api/type', type)

    return data
}

export const fetchTypes = async () => {
    const {data} = await $host.get('api/type')
    return data
}

export const createSpice = (spice) => {
    const {data} = $authHost.post('api/space', spice)

    return data
}

export const fetchSpice = async (payload) => {
    const   {typeId = '', page = 0, limit, searchValue} = payload || {}
    const {data} = await $host.get('api/space', {params: {
            typeId,
            page,
            limit, searchValue
        }})
    console.log(data, "data")
    return data
}

export const fetchOneSpice = async (id) => {
    const {data} = await $host.get('api/space/' + id)
    return data
    if(data) {}
}

export const downloadSpice = async() => {
    const {data} = await $host.get('/download')
    return data
}
export const searchSpice = async(search) => {
    const {data} = await $host.get(`api/space/search?search=${search}`)
    return data
}




import React from 'react';
import BaseModal from "../base-modal/BaseModal"

import style from "./SuccessModal.module.css";

const SuccessModal = ({isOpen, handleClose}) => (
    <BaseModal isOpen={isOpen} handleClose={handleClose}>
        <div className={style.wrap}>
            <div className={style.title}>
                Спасибо!
            </div>
            <div className={style.title}>
                Ваш запрос отправлен
            </div>
            <div className={style.info}>
                Мы свяжемся с вами в ближайшее время!
            </div>
        </div>
    </BaseModal>
);

export default SuccessModal;
import React, {useState} from 'react';
import style from './Main.module.css';
import styleContainer from '../../common/styles/Container.module.css';
import image1 from '../../img/main/Мах.png'
import instagramIcon from '../../img/main/Instagram.png';
import facebookIcon from '../../img/main/Facebook.png'
import BottonsBlock from "../../components/mainBlock/MainBottonsBlock";
import RequestCall from "../../components/requestCall/RequestCall";
import AskQuestion from "../../components/askQuestion/AskQuestion";


const Main = () => {

    return (
        <div className={style.main_container}>
            {/*<div className={style.line}></div>*/}
            <div className={styleContainer.container}>
                <div className={style.main_block}>

                    <BottonsBlock title="О нас" />
                    <div className={style.photo}>
                        <img src={image1} alt="Max" />
                        <div className={style.photoBlockText}>«Работать честно, выполнять обязательства и идти навстречу своим постоянным и потенциальным клиентам!»</div>
                    </div>
                    <div className={style.information}>
                        <div className={style.informationText}>
                            <p>Компания ООО «СтасмаксТрейд» уже более 10 лет поставляет на Белорусский рынок специи, пряности, сушеные овощи и
                                многие другие ингредиенты практически для всех отраслей пищевой промышленности в рынках B2B и HoReCa. Большое значение уделяется выбору производителей сырья,
                                отдавая предпочтение тем предприятиям, которые обладают современной технологической базой и способны выполнять самые жесткие требования по качеству и пищевой безопасности своей продукции.
                            </p>
                            <p className={style.informationText1}>Мы предлагаем качественную продукцию оптом от лучших производителей. Мы не стремимся совершить одну-две выгодные для нас сделки , не учитывающие интересы наших партнеров.
                                Наша цель — долгосрочное и взаимовыгодное сотрудничество. Наши Клиенты выбирают нас за лучшее соотношение цена-качество.</p>
                            <p className={style.informationText1}> Компания ООО «СтасмаксТрейд» приглашает все заинтересованные организации к долгосрочному и взаимовыгодному сотрудничеству. Возможно, мы окажемся полезны и для Вас,
                                и Мы вместе с Вами найдем оптимальные решения!</p>
                        </div>
                        <div className={style.socialNetworkLink}>
                            <div>Мы в соц.сетях: @stasmakstreid</div>
                            <div className={style.socialLinkIcon}>
                                <a href="https://www.instagram.com/stasmakstreid">

                                    <img src={instagramIcon} alt="Instagram"/>
                                </a>
                                <a href="#"><img src={facebookIcon} alt="Facebook"/> </a>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>

    );
}

export default Main;

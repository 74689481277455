import React, {useState} from 'react';
import style from './HowToBuy.module.css'
import styleContainer from "../../common/styles/Container.module.css";
import BottonsBlock from "../../components/mainBlock/MainBottonsBlock";
import RequestCall from "../../components/requestCall/RequestCall";
import AskQuestion from "../../components/askQuestion/AskQuestion";

const HowToBuy = () => {
    return (
        <div className={style.container}>
            <div className={styleContainer.container}>
                <div className={style.block}>
                   <BottonsBlock title="Как купить" />
                    <div className={style.information}>
                        <div >
                            <h3 className={style.title}>1. Заказать:</h3>
                            <div>
                                <div>1. Сообщить перечень и количество приобретаемого товара, а также реквизиты компании/физ.</div>
                                <div>2. После оформления и согласования вашего заказа, мы высылаем счет на оплату и договор.</div>
                            </div>
                        </div>
                        <div>
                            <h3 className={style.title}>2. Оплатить:</h3>
                            <div>Мы работаем только по безналичному расчёту.
                                Физические лица могут оплатить счет в любом банковском отделении, либо с
                                помощью системы «Интернет-банкинг».</div>
                        </div>
                        <div>
                            <h3 className={style.title}>3. Получить:</h3>
                            <div>
                                <p>Самовывоз товара осуществляется по адресу:
                                    г. Минск, ул. Каменногоррмкая, д. 45, склад 11А/2
                                    с 9:00-17:00 пн-пт.</p>
                                <p>Как правило, товар готов на следующий день после поступления денежных средств.
                                    Доставка товара осуществляется транспортной компанией во все регионы Республики Беларусь,
                                    стоимость доставки зависит от габаритов и веса товара.</p>


                            </div>
                        </div>
                        <div className={style.delivery}>
                            <div> От 25 кг. по Минску доставка бесплатно</div>
                            <div>От 50 кг. по РБ доставка бесплатно</div>
                        </div>
                    </div>
                    <div className={ `${style.information} ${style.information_handwriting}`}>
                        <h3 className={style.title}>Реквизиты для оплаты:</h3>
                        <div>
                            <p>ООО "СТАСМАКСТРЕЙД"</p>
                            <p>УНП: 191754472</p>
                           <p>Р/С (бел.руб.)</p>
                            <p>BY56UNBS30120939900050000933 в ЗАО «БСБ БАНК»</p>
                            <p>Код (BIC): UNBSBY2X</p>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default HowToBuy;
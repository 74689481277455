import React, {useContext, useState} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import style from "./pages.module.css";
import next from "../../img/catalog/next.png"
import back from "../../img/catalog/back.png"

const Pages = observer(() => {

    const {spices} = useContext(Context)
    const count = spices.totalCount
    const limit = 16


    const pageCount = Math.ceil(count/limit)
    console.log({pageCount, count})
        const pages = [];
        for (let i = 0; i < pageCount; i++) {
            pages.push(i + 1)
        }
        let portionSize = 4
        let portionCount = Math.ceil(pageCount / portionSize)

    let [portionNumber, setPortionNumber] = useState(1)
        let leftPortionPageNumber = (portionNumber - 1) *  portionSize + 1
        let rightPortionPageNumber = portionNumber *  portionSize




    return (
        <div className={style.pagination_block}>
            {portionNumber > 1 &&
                <button className={style.btn} onClick={() => {
                    setPortionNumber(portionNumber - 1)
                }}>
                    <img src={back} alt="back"/>
                </button>}


            {pages
                .filter(p => p >= leftPortionPageNumber && p <= rightPortionPageNumber)
                .map((page =>
            <span className={spices.page === page ? style.pagination_block_item_active : style.pagination_block_item}
                  key={page}
                  onClick={() => spices.setPage(page)}>{page}</span>))}

            {portionCount > portionNumber &&
                <button className={style.btn} onClick={() => {
                    setPortionNumber(portionNumber + 1)
                }}><img src={next} alt="next"/></button>
            }
            
        </div>
    );
}
);

export default Pages;
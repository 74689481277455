import React, { useContext, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { Context } from "../../index";
import style from "./TypeBar.module.css";
import { fetchTypes } from "../../http/spiceAPI";
import {useDispatch} from "react-redux";
import {getCategories} from "../../features/categories/categoriesSlice";

const TypeBar = observer(({title}) => {
  // const dispatch = useDispatch();
  //
  // useEffect(() => {
  //   dispatch(getCategories())
  // }, [dispatch])
  const { spices } = useContext(Context);
  const navigate = useNavigate();
  let location = useLocation();

  const onCategoriesClick = (type) => {
    if (location.pathname !== "/catalog") {
      navigate("/catalog");
    }

    spices.setSelectedType(type);
  };

  let sortSpices = spices?.types?.slice().sort((a, b) => a.id - b.id);

  useEffect(() => {
    fetchTypes().then((data) => spices.setTypes(data));
  }, []);

  return (
    <div className={style.typeBarBlock}>
      <h3 className={style.catalog_title}>{title}</h3>
      {sortSpices.map((type) => {
        return (
          <div
            className={style.categories}
            onClick={() => onCategoriesClick(type)}
            key={type.id}
          >
            <div
              className={type.id === spices.selectedType.id ? style.wrapActive : style.wrap}
            >
              {type.name}
            </div>
          </div>
        );
      })}
      {/*<div><a href="#">Специи</a></div>*/}
      {/*<div><a href="#">Сушеные овощи</a></div>*/}
      {/*<div><a href="#">Травы</a></div>*/}
      {/*<div><a href="#">Смеси пряностей</a></div>*/}
      {/*<div><a href="#">Сухофрукты, орехи</a></div>*/}
      {/*<div><a href="#">Пищевые добавки и другое</a></div>*/}
    </div>
  );
});

export default TypeBar;

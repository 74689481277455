import React from 'react';
import style from "../Nav.module.css";
import menu from "../../../img/menu/menu1.png";
import menuMedia from "../../../img/menu/menu1Media.png";

const BurgerMenu = ({onBurgerClick}) => {
    return (
        <div>
            <button className={style.burger} aria-label="Open menu" onClick={onBurgerClick}>
                <img className={style.menu} src={menu} alt="menu"/>
                <img className={style.menuMedia} src={menuMedia} alt="menu"/>
                {/*<span className={style.burgerLine}></span>*/}
                {/*<span className={style.burgerLine}></span>*/}
                {/*<span className={style.burgerLine}></span>*/}
            </button>
        </div>
    );
};

export default BurgerMenu
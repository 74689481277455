import React, {useState} from 'react';
import style from './Contacts.module.css';
import styleContainer from '../../common/styles/Container.module.css';
import BottonsBlock from "../../components/mainBlock/MainBottonsBlock";
import whatsApp from '../../img/contacts/whatsApp.png';
import viber from '../../img/contacts/Viber.png';
import telegram from '../../img/contacts/Telegram.png';
import mapPicture from '../../img/contacts/Capdfdfture 1.png';
import mapPicture1366 from '../../img/contacts/Capdfdfture1366.png';
import mapPicture360 from '../../img/contacts/Capdfdfture360.png';
import RequestCall from "../../components/requestCall/RequestCall";
import AskQuestion from "../../components/askQuestion/AskQuestion";


const Contacts = () => {

    return (
        <div className={style.contacts_container}>
            {/*<div className={style.line}></div>*/}
            <div className={styleContainer.container}>
                <div className={style.contacts_block}>

                    <BottonsBlock title="Контакты"/>
                    <div className={style.requisites}>
                        <div >
                            <h3 className={style.title}>Офис: </h3>
                            <div className={style.information_data}>
                                <span>г. Минск, ул. Каменногорская, 45, офис 11</span><br/>
                                <span> пн-пт 9:00-17:00, без обеда</span>
                            </div>
                            <div className={style.information_data}>
                                <span>+375 25 549 42 70</span><br/>
                                <span> info@stasmax.by</span>
                            </div>


                        </div>
                        <div>
                            <h3 className={style.title}>Склад: </h3>
                            <div className={style.information_data}>
                                <span>г. Минск, ул. Каменногорская, 45, склад 11А/2</span><br/>
                                <span> пн-пт 9:00-17:00, без обеда</span>
                            </div>
                            <div className={style.information_data}>
                                <span>+375 25 549 42 70</span>
                            </div>


                        </div>
                        <div className={style.payment_details}>
                            <h3 className={style.title}>Реквизиты для оплаты: </h3>
                            <p>ООО "СТАСМАКСТРЕЙД"</p>
                            <p>УНП: 191754472</p>
                            <p>Р/С(бел.руб.):</p>
                            <p>BY56UNBS30120939900050000933 в ЗАО «БСБ БАНК»</p>
                            <p>Код (BIC): UNB2BY2X</p>
                        </div>
                        <div>
                            <h3 className={style.title}>Юридический и почтовый адрес: </h3>
                            <div>
                                <span>220055, Беларусь, г. Минск, ул. Каменногорская, 45,</span><br/>
                                <span>офис 11</span>
                            </div>

                        </div>

                    </div>
                    <div className={style.information}>
                        <div className={style.information_text}>По всем вопросам, пожеланиям или замечаниям касательно работы нашей организации вы можете
                            обратиться по телефону:</div>
                        <div className={style.informationIcon}>
                            <a href="https://wa.clck.bar/375255494270"><img src={whatsApp} alt="whatsApp"/></a>
                            <a href="viber://chat?number=+375255494270"><img src={viber} alt="viber"/></a>
                            <a href="https://t.me/Stasmakstreid"><img src={telegram} alt="telegram"/></a>
                        </div>
                        <div className={style.informationPhone}>
                            <a href="tel:+375255494270"><span>+375 29 797 50 34</span></a>
                        </div>
                        <div className={style.informationEmail}>
                            <a href="mailto:stasmakstreid@mail.ru"><span>e-mail: stasmakstreid@mail.ru</span></a>
                        </div>

                        <div className={style.map}>
                            <img className={style.mapImage} src={mapPicture} alt="mapPicture"/>
                            <img className={style.mapImage1366} src={mapPicture1366} alt="mapPicture"/>
                            <img className={style.mapImage360} src={mapPicture360} alt="mapPicture"/>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    );
}

export default Contacts;

import React, {useContext, useState} from 'react';
import style from './Nav.module.css';
import whatsAppIcon from '../../img/header/nav/whatsapp.svg';
import viberIcon from '../../img/header/nav/viber.svg';
import telegramIcon from '../../img/header/nav/telegram.svg';
import phoneIcon from '../../img/header/nav/phone.svg';
import searchIcon from '../../img/header/nav/searchIcon.svg';
import userIcon from '../../img/header/nav/User.png';
import basketIcon from '../../img/header/nav/Component 61.png'
import {Context} from "../../index";
import BurgerMenu from "./burgerMenu/BurgerMenu";
import {observer} from "mobx-react-lite";
import Auth from "../../pages/auth/Auth";
import {Link} from "react-router-dom";


const Nav = observer(({menuOpened, setMenuOpened, onBurgerClick}) => {
    const {user} = useContext(Context);
    const [modalActive, setModalActive] = useState(true)

    // const onBurgerClick = () => {
    //     setMenuOpened(!menuOpened);
    // };

    return (
        <div className={style.nav}>
            <div className={style.nav_message}>

                <div>
                    <a href="viber://chat?number=+375255494270"><img src={viberIcon} alt="Viber"/></a>
                    {/*Для мобильной версии  <a href="viber://mobile?number=+375255494270"><img src={viberIcon} alt="Viber"/></a>*/}
                    <a href="https://t.me/Stasmakstreid"><img src={telegramIcon} alt="Telegram"/></a>
                    <a href="https://wa.clck.bar/375255494270"><img src={whatsAppIcon} alt="WhatsApp"/></a>
                    <a href="tel:+375255494270"><img src={phoneIcon} alt="Phone"/></a>
                </div>

                <div>
                    <div>
                        <a href="tel:+375255494270"><span className={style.nav_phone}>+375 25 549 42 70 </span></a>
                    </div>

                    <div>
                        <a href="tel:+375256025517"><span className={style.nav_phone}>+375 25 602 55 17 </span></a>
                    </div>
                </div>


            </div>
            {/*<div className={style.phone_number_block}>*/}

            {/*</div>*/}
            <div className={style.search}><img  src={searchIcon} alt="search"/></div>
            <div>
                <Link to={'/basket'}>
                     <span>
                    <img src={basketIcon} alt="img"/>
                </span>
                </Link>

            </div>
            <div>
                <BurgerMenu onBurgerClick={onBurgerClick}/>
            </div>


            {/*<a href="/login"><img src={userIcon} alt="User"/></a>*/}
            {/*<a href="#"><img src={basketIcon} alt="Basket"/></a>*/}


            {/*<button className={style.burger} aria-label="Open menu">*/}
            {/*<span className={style.burgerLine}></span>*/}
            {/*<span className={style.burgerLine}></span>*/}
            {/*<span className={style.burgerLine}></span>*/}
            {/*</button>*/}

        </div>
    );
})

export default Nav;

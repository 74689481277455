import React, {useContext} from 'react';
import style from "./PersonalAccount.module.css";
import styleContainer from '../../common/styles/Container.module.css';
import TypeBarForProfile from "../../components/typeBarForProfile/TypeBarForProfile";
import ButtonBlock from "../../components/buttonBlock/ButtonBlock";
import {Context} from "../../index";
import {observer} from "mobx-react-lite";


const PersonalAccount = observer(() => {
    const {user} = useContext(Context)


    const logOut = () => {
        user.setUser({})
        user.setIsAuth(false)
    }
    return (
        <div className={style.profile_container}>
            <div className={styleContainer.container}>
                <div className={style.profile_block}>
                    <div className={style.wrapper}>

                        <TypeBarForProfile logOut={logOut}/>


                        <ButtonBlock />


                    </div>
                    <div className={style.myData}> Мои данные</div>
                </div>

            </div>

        </div>
    );
});

export default PersonalAccount;
import React, {useContext, useEffect, useState} from 'react';
import style from './Header.module.css';
import Nav from "./nav/Nav";
import logoImage from '../img/header/Logo (2).svg';
import logoImageForMedia from '../img/header/LogoMedia.png';
import styleContainer from '../common/styles/Container.module.css'
import {Context} from "../index";
import {Link} from "react-router-dom";
import {searchSpice, fetchSpice} from "../http/spiceAPI";

const Header = ({menuOpened, setMenuOpened, onBurgerClick}) => {
    const {spices} = useContext(Context)

    const [value, setValue] =  useState('');

    const onSearchValue = e => {
        setValue(e.currentTarget.value);
       spices.setSearchValue(e.currentTarget.value)
    };

    useEffect(() => {
    const debounceTimer = setTimeout(() => { 
        if (value){
            fetchSpice({searchValue: value}).then(data => {
                spices.setSpices(data.rows)
            })
        }

        }, 1000)

        return () => clearTimeout(debounceTimer)

    }, [value])
    
    return (
        <div className={style.header_container}>
            <div className={styleContainer.container}>
                <div className={style.header_block}>

                    <Link to={'/catalog'} className={style.header_logo_block}>
                        <a href="#" className={style.header_logo}>
                            <img src={logoImage} alt="Smax"/>
                        </a>
                        <a href="#" className={style.header_logo_for_media}>
                            <img src={logoImageForMedia} alt="Smax"/>
                        </a>
                    </Link>
                    <div className={style.header_input_block}>
                        <input className={style.inputForHeader}
                               type="text"
                               value={value}
                               placeholder="Введите для поиска"
                               onChange={onSearchValue}
                        />
                    </div>

                    <Nav menuOpened={menuOpened} setMenuOpened={setMenuOpened} onBurgerClick={onBurgerClick}/>
                </div>

            </div>
            <div className={style.line}></div>

        </div>
    );
}

export default Header;

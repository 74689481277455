 import {makeAutoObservable} from "mobx";









export default class CatalogStore {
    constructor() {

        this._types = []
        this._spices = []
        this._selectedType = {}
        this._page = 1
        this._totalCount = 0
        this._limit = 16
        this._searchValue = ''
        this._basket = []

        makeAutoObservable(this)
    }

    setTypes(types) {
        this._types = types
    }

    setSpices(spices) {
        this._spices = spices
    }

    setSelectedType(type) {
        this.setPage(1)
        this._selectedType = type
    }

    setPage(page) {
        this._page = page
    }
    setTotalCount(count) {
        this._totalCount = count
    }

    setSearchValue(value) {
        this._searchValue = value
    }

    setOrders(orders) {
        debugger;
        this._orders = orders

    }
    addCart = (cart) => {
        console.log({ orders: this._orders, cart })
        // this.setOrders([...this._orders.push(cart)])
        this._basket.unshift(cart) // [...this._basket, cart]
    }
    updateCart = (id, cart) => {
        this._orders = this._orders.map((item) => {
            if(item.id === id) {
                return {
                    id,
                    ...cart
                }
            }
            return item;
        })
    }
    removeProduct = (id) => {
       this._basket = this._basket.filter((item) => item.id !== id)
    }

    changeProductQuantity = (newQuantity, id) => {

        this._basket = this._basket.map(product => product.id === id ? ({...product, quantity: newQuantity}): product)
    }


    get searchValue() {
        return this._searchValue
    }

    get types() {
        return this._types
    }

    get spices() {
        return this._spices
    }

    get selectedType() {
        return this._selectedType
    }
    get totalCount() {
        return this._totalCount
    }
    get page() {
        return this._page
    }
    get limit() {
        return this._limit
    }
    get basket() {
        return this._basket
    }



}

import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {fetchTypes} from "../../http/spiceAPI";


export const getCategories = createAsyncThunk(
    'categories/getCategories',
    async (_, thunkAPI) => {
        try {
            const res = await axios(`http://be.smax.by/api/type`)
            return res.data

        } catch (err) {
            console.log(err);
            return thunkAPI.rejectWithValue(err)
        }
    }

)

const categoriesSlice = createSlice({
    name: 'categories',
    initialState: {
        list: [],
    },
    extraReducers: (builder) => {
        builder.addCase(getCategories.fulfilled, (state, { payload }) => {
            state.list = payload;
        } )
    }
})

export default categoriesSlice.reducer;
import React from "react";
import { createPortal } from "react-dom";
// import { combineCss } from "../../../common/helpers/combineCss";
import closeIcon from "../../../img/closed.svg";

import styles from "./BaseModal.module.css";

const Modal = ({
  children,
  backgroundStyle,
  modalStyle,
  handleClose,
  isOpen,
}) =>
  isOpen
    ? createPortal(
        <>
          <div
            style={{ ...backgroundStyle }}
            className={styles.background}
            onClick={handleClose}
          />
          <div style={{ ...modalStyle }} className={styles.modal}>
            <div className={styles.escape}>
              <div className={styles.closeButton} onClick={handleClose}>
                <img src={closeIcon} alt="close" />
              </div>
            </div>
            {children}
          </div>
        </>,
        document.body
      )
    : null;

export default Modal;

import React from 'react';
import style from "./MainBottonsBlock.module.css";
import ButtonBlock from "../buttonBlock/ButtonBlock";


const BottonsBlock = ({title}) => {
    return (


        <div className={style.wrapper}>

            <h3 className={style.buttonsBlock_title}>{title}</h3>
            <ButtonBlock />
            {/*<div>*/}
                {/*<button className={style.mainButton}>Скачать прайс</button>*/}
            {/*</div>*/}
            {/*<div>*/}
                {/*<button className={style.mainButton} onClick={() => setModalActiveQuestion(true)}>Задать вопрос</button>*/}
            {/*</div>*/}
            {/*<div>*/}
                {/*<button className={style.mainButton} onClick={() => setModalActive(true)}>Заказать звонок</button>*/}
            {/*</div>*/}


        </div>

    );
}

export default BottonsBlock;

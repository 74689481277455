import React, {useContext, useState} from 'react';
import style from './Card.module.css';
import basket from '../../../img/catalog/cards/basket.png'
import basketRed from '../../../img/header/nav/ComponentRed.png'
import { useNavigate } from "react-router-dom"
import {Context} from "../../../index";


const Card = ({spice}) => {
    const {spices} = useContext(Context)
    const navigate = useNavigate()
    const [count, setCount] = useState(1)
    const [colorPlace, setColorPlace] = useState(false)

    const handleClick = (cart) => {

        setColorPlace(true)
        setCount(cart.quantity)
        const newProduct = {...cart, quantity: count}
        spices.addCart(newProduct)

    }



    return (


            <div className={style.content_img} >
                <div className={style.card}>

                    <div onClick={() => handleClick(spice)} ><img className={style.basket} src={colorPlace ?  basketRed : basket} alt="card1"/></div>

                    <div onClick={() => {navigate('/catalog' + '/' + spice.id)}}>
                        {/*<img className={style.img_card} src={'http://be.smax.by/' + spice.img} alt="card1"/>*/}
                        <img className={style.img_card} src={'https://be.smax.by/' + spice.img} alt="card1"/>
                    </div>

                </div>

                <div className={style.titleCard}>{spice.name}</div>
                {/*<div className={style.price}>{spice.price + ` BYN/кг`}</div>*/}

                </div>
    );
}

export default Card;

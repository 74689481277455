import React, {useContext, useState} from 'react';
import style from './Auth.module.css'
import close from '../../img/auth/close.png'
import {NavLink, useLocation} from "react-router-dom";
import {login, registration} from "../../http/userAPI";
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import { useNavigate } from "react-router-dom"


const Auth = observer(() => {
    const {user} = useContext(Context)
    const location = useLocation()
    const isLogin = location.pathname === "/login"
    const navigate = useNavigate()

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")


    const click = async () => {
        try {
            let data;
            if(isLogin) {

                data = await login(email, password)
                console.log(data)
            } else {
                data = await registration(email, password)
                console.log(data)
            }
            user.setUser(user)
            user.setIsAuth(true)
            navigate('/profile')
        } catch (e) {
            // alert(e.response.data.message)
            console.log(e)
        }
    }


    return (


            <div className={style.auth_wrapper}>
                <div className={style.container}>
                    <form onSubmit={(event) => event.preventDefault()} action="" className={style.wrap} >

                        <div className={style.content}>
                            <h3>{isLogin ? 'Войти' : 'Регистрация'}</h3>
                            <input type="email"
                                   placeholder="Введите ваш email"
                                   className={style.phone_number}
                                   value={email}
                                   onChange={e => setEmail(e.target.value)}
                            />
                            <input type="password"
                                   placeholder="Введите ваш пароль"
                                   className={style.phone_number}
                                   value={password}
                                   onChange={e => setPassword(e.target.value)}
                            />
                            <button type="button" className={style.send_code_button} onClick={click}>{isLogin ? 'Войти' : 'Регистрация'}</button>
                            {/*<div>Войдите по номеру телефона</div>*/}
                            {/*<input type="text" placeholder="+375 (__) ___ __ __" className={style.phone_number}/>*/}
                            {/*<button className={style.send_code_button}>Получить код</button>*/}
                            {isLogin ?
                                <div>Нет аккаунта? <NavLink to={'/registration'}>Зарегистрируйтесь!</NavLink></div>
                                : <div>
                                    Есть аккаунт? <NavLink to={'/login'}>Войдите!</NavLink>
                                </div>
                            }
                            {/*<div>Или войти с помощью профиля</div>*/}
                            <div className={style.buttons_position}>
                                <button className={style.google_button}>Вход через Google</button>
                                <button className={style.facebook_button}>Вход через Facebook</button>
                            </div>
                            <div className={style.a_for_email}>
                                <a href="#" >Войти по почте</a>
                            </div>


                        </div>
                        <div><img src={close} alt="close"/></div>
                    </form>
                </div>


            </div>


    );
});

export default Auth;
import React, {useContext, useEffect, useState} from 'react';
import style from './Basket.module.css';
import styleContainer from "../../common/styles/Container.module.css";
import BottonBlock from "../../components/buttonBlock/ButtonBlock";
import TypeBar from "../../components/typeBar/TypeBar";
import decrement from "../../img/catalog/dec.png";
import increment from "../../img/catalog/inc.png";
import {Context} from "../../index";
import {fetchSpice, fetchTypes} from "../../http/spiceAPI";
import closedIcon from "../../img/basket/closed.png"
import {observer} from "mobx-react-lite";
import CatalogStore from "../../store/CatalogStore";


const Basket = observer(() => {
    const { spices } = useContext(Context);
   // const [basket, setBasket] = useState(spices?.basket)
    console.log({ basket: spices.basket })



    return (
        <div className={style.contacts_container}>
            <div className={styleContainer.container}>
                <div className={style.basket_block}>
                    <div>
                        <TypeBar title={"Корзина"} />
                        <BottonBlock />
                    </div>

                    <div className={style.basket_table}>
                        <table className={style.table}>
                            <tr>
                                <th style={{width: "6%"}} rowSpan={2}>№</th>
                                <th style={{width: "30%"}} rowSpan={2}>Наименование</th>
                                <th style={{width: "16%"}} rowSpan={2}>Количество, кг</th>
                                <th style={{width: "18%"}} colSpan={2}>Стоимость</th>

                                <th style={{width: "18%"}} rowSpan={2}>Всего с НДС BYN</th>
                                <th style={{width: "12%"}} rowSpan={2}>Удалить из списка</th>
                            </tr>
                            <tr>
                                <th>Без НДС<br/>BYN/кг</th>
                                <th>С НДС<br/>BYN/кг</th>
                            </tr>

                            {spices.basket?.map((product, index) => {

                                const onRemoveHandler = () => spices.removeProduct(product.id)

                                const incrementQuantity = () => {
                                    const newQuantity = product.quantity + 1
                                    spices.changeProductQuantity(newQuantity, product.id)
                                   // setBasket(prevState => prevState.map(s => s.id === product.id ? ({...s, quantity: newQuantity}) : s))
                                }

                                const decrementQuantity = () => {
                                    const newQuantity = product.quantity - 1
                                    spices.changeProductQuantity(newQuantity, product.id)
                                  //  setBasket(prevState => prevState.map(s => s.id === product.id ? ({...s, quantity: newQuantity}) : s))
                                }
                                let price
                                if(product.quantity >= 1 && product.quantity < 5) {
                                   price = product.price1
                                } else if (product.quantity >= 5 && product.quantity < 25) {
                                    price = product.price5
                                }else {
                                    price = product.price
                                }

                                return <>
                                <tr key={product.id}>
                                    <td>{index + 1}</td>
                                    <td>{product.name}</td>
                                    <td>
                                        <button className={style.btn} onClick={decrementQuantity}><img src={decrement}/></button>
                                        <span className={style.count}>{product.quantity}</span>
                                        <button className={style.btn} onClick={incrementQuantity}><img src={increment}/>
                                        </button>

                                    </td>
                                    <td>{price}</td>
                                    <td>{(price*1.2).toFixed(2)}</td>


                                    <td>{(price * product.quantity*1.2).toFixed(2)}</td>
                                    <td><button className={style.basketDeleteButton} onClick={onRemoveHandler} ><img src={closedIcon} alt=""/></button></td>
                                </tr>

                                </>
                            })}

                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Basket;
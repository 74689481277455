import React, {useContext, useEffect, useMemo, useState} from "react";
import style from "./Catalog.module.css";
import styleContainer from "../../common/styles/Container.module.css";

import { Context } from "../../index";
import { observer } from "mobx-react-lite";
import RequestCall from "../../components/requestCall/RequestCall";
import AskQuestion from "../../components/askQuestion/AskQuestion";
import Card from "./card/Card";
import TypeBar from "../../components/typeBar/TypeBar";
import ButtonBlock from "../../components/buttonBlock/ButtonBlock";
import {fetchSpice, fetchTypes, searchSpice} from "../../http/spiceAPI";
import Pages from "../../components/pages/Pages";
import {logDOM} from "@testing-library/react";

const Catalog = observer(() => {
  const { spices } = useContext(Context);

  const [modalActive, setModalActive] = useState(false);
  const [modalActiveQuestion, setModalActiveQuestion] = useState(false);
  const closeRequestCallModal = () => {
    setModalActive(false);
  };

  useEffect(() => {
    fetchTypes().then((data) => spices.setTypes(data));
    fetchSpice().then((data) => {
      spices.setSpices(data.rows);
      spices.setTotalCount(data.count);
    });
  }, []);

  useEffect(() => {
    fetchSpice({typeId: spices.selectedType.id, page: spices.page, searchValue: spices.searchValue }).then((data) => {
      spices.setSpices(data.rows);
      spices.setTotalCount(data.count);
    });
  }, [spices.page, spices.selectedType]);



  const data = useMemo(() => {
    const firstPageIndex = (spices.page -1) * 15
    const lastPageIndex = firstPageIndex + 15

    return spices.spices.slice(firstPageIndex, lastPageIndex)
  }, [spices.page, spices.spices])

  return (
    <div className={style.catalog_container}>
      <div className={styleContainer.container}>
        <div className={style.catalog_block}>
          <div className={style.wrapper}>
            <TypeBar title={"Каталог"} />
            <ButtonBlock />
          </div>
          <div className={style.catalog}>
            {data
              .filter((spice) =>
                spice.name
                  .toLowerCase()
                  .includes(spices.searchValue.toLowerCase())
              )
              .map((spice) => (
                <Card key={spice.id} spice={spice} />
              ))}

          </div>
        </div>
        <Pages />
      </div>
      <RequestCall
        active={modalActive}
        setActive={setModalActive}
        closeRequestCallModal={closeRequestCallModal}
      />
      <AskQuestion
        active={modalActiveQuestion}
        setActive={setModalActiveQuestion}
      />
    </div>
  );
});

export default Catalog;

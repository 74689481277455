
import axios from 'axios';

// export const API_URL = `http://be.smax.by/api`

const $host = axios.create({
     // baseURL: `http://be.smax.by/`
     baseURL: `https://be.smax.by/`
})
const $authHost = axios.create({
    // baseURL: `http://be.smax.by/`
    baseURL: `https://be.smax.by/`
})


const authInterceptor = config => {
    config.headers.authorization = `Bearer ${localStorage.getItem('token')}`
    return config
}

$authHost.interceptors.request.use(authInterceptor)

export {
    $host,
    $authHost
}

// const $api = axios.create({
//     withCredentials: true,
//     baseURL: API_URL
// })
import React, {useContext} from 'react';
import {observer} from "mobx-react-lite";
import {Context} from "../../index";
import style from "./TypeBarForProfile.module.css"
import { useNavigate } from "react-router-dom"

const TypeBarForProfile = observer(({logOut}) => {
    const {spices} = useContext(Context)
    const navigate = useNavigate()

    return (
        <div>
            <h3>Личный <br/> кабинет</h3>
            <div className={style.categories}>
                <div className={style.wrapper}>Мои данные</div>
                <div className={style.wrapper}>Мои заказы</div>
                <div className={style.wrapper}>Корзина</div>
                <div className={style.wrapper} onClick={() => logOut()}>Выйти</div>
            </div>


        </div>
    );
});

export default TypeBarForProfile;
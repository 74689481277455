import React, { useState } from "react";
import style from "./ButtonBlock.module.css";
import RequestCall from "../requestCall/RequestCall";
import AskQuestion from "../askQuestion/AskQuestion";
import {downloadSpice} from "../../http/spiceAPI";
import SuccessModal from "../modals/success-modal/SuccessModal";

const ButtonBlock = () => {
  const [isRequestCallModalVisible, setIsRequestCallModalVisible] = useState(false);
  const [isAskQuestionModalVisible, setIsAskQuestionModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

    const downloadPrice = () => {
        const url = downloadSpice();
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'file.docx'); // имя файла для сохранения
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

  const openRequestCallModal = () => setIsRequestCallModalVisible(true);
  const closeRequestCallModal = () => setIsRequestCallModalVisible(false);

  const openAskQuestionModal = () => setIsAskQuestionModalVisible(true);
  const closeAskQuestionModal = () => setIsAskQuestionModalVisible(false);

  const openSuccessModal = () => setIsSuccessModalVisible(true);
  const closeSuccessModal = () => setIsSuccessModalVisible(false);

  const submitRequest = () => {
    setIsRequestCallModalVisible(false);
    setIsAskQuestionModalVisible(false);
    openSuccessModal(true);
  };

  return (
    <div className={style.mainButtonBlock}>
      <div>
        <button className={style.mainButton} onClick={downloadPrice}>Скачать прайс</button>
      </div>
      <div>
        <button
          className={style.mainButton}
          onClick={openAskQuestionModal}
        >
          Задать вопрос
        </button>
      </div>
      <div>
        <button
          className={style.mainButton}
          onClick={openRequestCallModal}
        >
          Заказать звонок
        </button>
      </div>
      <RequestCall
        isOpen={isRequestCallModalVisible}
        submitRequest={submitRequest}
        handleClose={closeRequestCallModal}
      />
      <AskQuestion
        isOpen={isAskQuestionModalVisible}
        handleClose={closeAskQuestionModal}
        submitRequest={submitRequest}
      />
      <SuccessModal isOpen={isSuccessModalVisible} handleClose={closeSuccessModal} />
    </div>
  );
};

export default ButtonBlock;

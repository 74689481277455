import React, {useContext, useEffect, useState} from 'react';
import styleContainer from "../../common/styles/Container.module.css";
import style from "./CardPage.module.css";
import RequestCall from "../../components/requestCall/RequestCall";
import AskQuestion from "../../components/askQuestion/AskQuestion";
import TypeBar from "../../components/typeBar/TypeBar";
import basket from "../../img/catalog/cards/basket.png";
import ButtonBlock from "../../components/buttonBlock/ButtonBlock";
import {NavLink, useParams} from 'react-router-dom'
import decrement from "../../img/catalog/dec.png"
import increment from "../../img/catalog/inc.png"
import {fetchOneSpice} from "../../http/spiceAPI";
import arrow from "../../img/catalog/cards/Arrow 3.png"
import {Context} from "../../index";
import ActionCard from "../../components/actionCard/ActionCard";

const CardPage = () => {
    const {spices} = useContext(Context)
    const {id} = useParams()
    const [space, setSpace] = useState({info: []})
    // const [modalActive, setModalActive] = useState(false)
    // const [modalActiveQuestion, setModalActiveQuestion] = useState(false)
    // const {spices} = useContext(Context)
    // const spice = {id: 1, name: "Смесь для глинтвейна", img: card1}
    // const [spice, setSpice] = useState({info: []})
    const [count, setCount] = useState(1)


    const handleDec = () => {
        if (count > 1) {
            setCount(count - 1)
        }

    }
    const handleInc = () => {
        setCount(count + 1)
    }

    const addItemToBasket = (cart) => {
        const newProduct = {...cart, quantity: count}
        spices.addCart(newProduct)
    }



    // const spice = spices?.spices?.find(el => el.id === id)

    useEffect(() => {
        fetchOneSpice(id).then(data => {
                setSpace(data)
            console.log({ spices, data }, data.quantity)
            setCount(data.quantity)
                // spices.addCart(data)
            }
        )
    }, [])
    // console.log(spice.composition)


    return (
        <div className={style.catalog_container}>
            <div className={styleContainer.container}>
                <div className={style.catalog_block}>

                    <div className={style.wrapper}>
                        <TypeBar title={"Каталог"}/>
                        <ButtonBlock/>

                    </div>
                    <div className={style.imageBlock}>
                        <div className={style.buttonForMedia}>
                            <NavLink to={'/catalog'}>
                                <button><img src={arrow} alt="arrow"/></button>
                            </NavLink>

                        </div>

                        <div className={style.spice}>
                            {/*<img src={'http://be.smax.by/' + space.img}/>*/}
                            <img src={'https://be.smax.by/' + space.img}/>
                        </div>
                    </div>
                    <div className={style.infoBlock}>
                        <h3>
                            {space.name}
                        </h3>
                        <div className={style.price}>

                            <table className={style.table}>
                                <tbody>
                                <tr>
                                    <td>От 1 кг <br/> {space.price1 + ' BYN'}  </td>
                                    <td>От 5 кг <br/> {space.price5 + ' BYN'} </td>
                                    <td >От 25 кг  <br/> {space.price + ' BYN'} </td>
                                </tr>
                                <tr>
                                    <td>
                                        <button className={style.btn} onClick={handleDec}><img src={decrement}/>
                                        </button>
                                    </td>
                                    <td className={style.count} >{count + ' кг'}</td>
                                    <td>
                                        <button className={style.btn} onClick={handleInc}><img src={increment}/>
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <div className={style.text}>{'Цена' + ' без НДС'}</div>
                        </div>
                        <button className={style.cardButton} onClick={() => addItemToBasket(space, count)}>Положить в корзину</button>

                        <div className={style.description}>
                            <h2>Описание:</h2>
                            <div>{space.description}</div>


                        </div>

                            {
                                space.composition
                                    ? <div className={style.composition}>
                                        <h2>Состав:</h2><div>{space.composition}</div>
                                    </div>
                                    : ""
                            }


                    </div>




                </div>
                {/*<div className={style.actionCards}>*/}
                {/*    <h2>Вам может пригодиться:</h2>*/}
                {/*    <div className={style.actionCard}>*/}
                {/*        <ActionCard  />*/}
                {/*        <ActionCard />*/}
                {/*        <ActionCard />тзь ы*/}
                {/*        <ActionCard />*/}
                {/*        <ActionCard />*/}
                {/*    </div>*/}

                {/*</div>*/}
            </div>
            {/*<RequestCall active={modalActive} setActive={setModalActive}/>*/}
            {/*<AskQuestion active={modalActiveQuestion} setActive={setModalActiveQuestion}/>*/}
        </div>

    );
}

export default CardPage;
import React from 'react';
import style from './Footer.module.css';
import logoImage from '../img/header/Logo.png'
import emailImage from '../img/footer/mail.svg'
import phoneImage from '../img/footer/phone.svg'
import addressImage from '../img/footer/location.svg'
import timeImage from '../img/footer/clockTime.svg';
import instagramImage from '../img/footer/Insta.svg';
import facebookImage from '../img/footer/Fasebook.svg';


import {Link, NavLink} from "react-router-dom";


const Footer = () => {
    return (
        <div className={style.footer_container}>
            <div className={style.line}></div>
            <div className={style.container}>
                <div className={style.footer_wrap}>
                    <div className={style.footer_logo}>

                            <img src={logoImage} alt="Smax"/>

                    </div>
                    <div className={style.footer_content}>
                        <div className={style.footer_info}>
                            <div className={style.footer_info_email}>
                                <span><img  src={emailImage} alt="email"/></span>
                                <a href="mailto:info@smax.com">info@smax.com</a>
                            </div>
                            <div className={style.footer_info1}>
                                <span> <img src={phoneImage} alt="phone"/></span>
                                <a href="tel:+375 25 549 42 70 ">
                                    +375 25 549 42 70
                                </a>
                            </div>
                            <div>
                                <a href="https://goo.gl/maps/N7Jpv2LjryJ1PqVFA">
                                    <span> <img src={addressImage} alt="address"/></span>
                                    <span>РБ, г. Минск, ул. Каменногорская, 45</span>
                                </a>

                            </div>
                            <div className={style.workingOurs}>
                                <span> <img src={timeImage} alt="address"/></span>
                                <span>9:00 - 17:00, сб, вс — выходной</span>
                            </div>
                            <div className={style.footer_info1}>
                                < input className={style.searchInput} type="text" placeholder="Введите для поиска"/>
                            </div>
                        </div>
                        <div className={style.footer_block}>
                            <div className={style.footer_block1}><Link to={'/catalog'}><span>Каталог</span></Link></div>
                            <div className={style.footer_block1}><Link to={'/kakkupit'}><span>Как купить</span></Link></div>
                            <div className={style.footer_block1}><Link to={'/about'}><span>О нас</span></Link></div>
                            <div className={style.footer_block1}><Link to={'/contacts'}><span>Контакты</span></Link></div>
                            <div className={style.footer_block1}><Link to={'/basket'}><span>Корзина</span></Link></div>
                            {/*<div className={style.footer_block1}><Link to={'/admin'}><span>Личный кабинет</span></Link></div>*/}
                            <div><a href={"https://www.instagram.com/stasmakstreid"}><img src={instagramImage} alt="instagram"
                                            style={{width: "20px", height: "20px"}}/></a></div>
                            <div><a href="https://www.facebook.com/people/%D0%A1%D1%82%D0%B0%D1%81%D0%BC%D0%B0%D0%BA%D1%81%D1%82%D1%80%D0%B5%D0%B9%D0%B4-%D0%9C%D0%B0%D0%BA%D1%81%D0%B8%D0%BC/100083835667510/"><img src={facebookImage} alt="instagram"
                                            style={{width: "20px", height: "20px"}}/></a>
                            </div>
                        </div>
                        <div className={style.footer_block_catalog}>
                            <div><a href="#">Специи, пряности, сушенные овощи</a></div>
                            <div><a href="#">Смеси пряностей и специй</a></div>
                            <div><a href="#">Орехи и сухофрукты</a></div>
                            <div><a href="#">Пищевые добавки</a></div>
                            <div><a href="#">Другое</a></div>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    );
}

export default Footer;

import React from 'react';
import style from './Menu.module.css'
import {NavLink} from "react-router-dom";
import menu from '../../../img/menu/menu.png';
import basket from '../../../img/menu/basket.png'
import whatsAppIcon from "../../../img/header/nav/icon.png";
import viberIcon from "../../../img/header/nav/viber.png";
import telegramIcon from "../../../img/header/nav/telegram.png";

const Menu = ({menuOpened, setMenuOpened, onBurgerClick}) => {
    return (
        <div className={menuOpened ? style.menu : style.menu && style.menuActive} onClick={() => setMenuOpened(false)}>

            <div className={style.menu__content} onClick={e => e.stopPropagation()}>
                <div className={style.menu_header}>
                    {/*<div>*/}
                    {/*<img src={basket} alt="basket"/>*/}
                    {/*</div>*/}
                    <div onClick={onBurgerClick}>
                        <img src={menu} alt="menu"/>
                    </div>
                </div>
                <div className={style.content}>
                    <div className={style.line}>

                    </div>
                    <div className={style.menu_info}>
                        <div>
                            <NavLink
                                className={style.item}
                                to={'/catalog'}
                                // onClick={() => {
                                //     onBurgerClick();
                                // }}
                            >
                                <h3 className={style.menu_title} onClick={() => setMenuOpened(false)}>Каталог</h3>
                            </NavLink>
                        </div>
                        <div>
                            <NavLink
                                className={style.item}
                                to={'/kakkupit'}>
                                <h3 className={style.menu_title} onClick={() => setMenuOpened(false)}>Как купить</h3>
                            </NavLink>
                        </div>
                        <div>
                            <NavLink
                                className={style.item}
                                to={'/contacts'}>
                                <h3 className={style.menu_title} onClick={() => setMenuOpened(false)}>Контакты</h3>
                            </NavLink>
                        </div>
                        <div>
                            <NavLink
                                className={style.item}
                                to={'/about'}>
                                <h3 className={style.menu_title} onClick={() => setMenuOpened(false)}>О нас</h3>
                            </NavLink>
                        </div>
                        {/*<div>*/}
                        {/*<NavLink*/}
                        {/*to={'/admin'}>*/}
                        {/*<h3 className={style.menu_title}>Личный кабинет</h3>*/}
                        {/*</NavLink>*/}
                        {/*</div>*/}
                        {/*<div>*/}
                        {/*<NavLink*/}
                        {/*to={'/basket'}>*/}
                        {/*<h3 className={style.menu_title}>Корзина</h3>*/}
                        {/*</NavLink>*/}
                        {/*</div>*/}

                    </div>


                </div>
                <div className={style.menu_contacts}>
                    <div className={style.menu_contacts_phone}>
                            <a href="tel:+375 25 549 42 70 ">+375 25 549 42 70</a>
                    </div>
                    <div className={style.menu_messengers}>
                        <div>
                            <a href="https://wa.clck.bar/375255494270"><img src={whatsAppIcon} alt="WhatsApp"/></a>
                        </div>
                        <div>
                            <a href="viber://chat?number=+375255494270"><img src={viberIcon} alt="Viber"/></a>
                            {/*Для мобильной версии  <a href="viber://mobile?number=+375255494270"><img src={viberIcon} alt="Viber"/></a>*/}
                        </div>
                        <div>
                            <a href="https://t.me/Stasmakstreid"><img src={telegramIcon} alt="Telegram"/></a>
                        </div>
                    </div>


                </div>

            </div>


        </div>

    );
};

export default Menu;
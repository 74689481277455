import React from 'react';

const AdminPage = () => {
    return (
        <div>
           <button>Добавить тип</button>

            <button>Добавить позицию</button>
        </div>
    );
};

export default AdminPage;